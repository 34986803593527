<template>
  <div class="base-card" :style="baseCardStyle">
    <div class="card-header" :style="cardHeaderStyle">
      <div class="card-name">{{ props.cardName }}</div>
      <div class="card-icon">
        <slot name="icon">
          <FileFolderIcon role="button"></FileFolderIcon>
        </slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useStore } from 'vuex'
import { themeColors } from '@/common/shared.js'
import FileFolderIcon from '../../icons/FileFolderIcon.vue'

const store = useStore()

const props = defineProps({
  cardName: String,
})

const baseCardStyle = computed(() => ({
  backgroundColor: themeColors[store.getters.currentThemeName].toolBackgroundColor,
  boxShadowColor: '#b9b9b9',
}))

const cardHeaderStyle = computed(() => ({
  borderColor: 'rgba(0, 0, 0, 0.1)',
  color: themeColors[store.getters.currentThemeName].toolHeaderTextColor,
}))
</script>

<style lang="scss" scoped>
.base-card {
  border-radius: 16px;
  box-shadow: 0 0 3px #b9b9b9;
  height: 40vh;
}

.card-header {
  border-bottom: 0.5px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1.75rem 1.5rem;

  &>.card-icon {
    width: 1.4rem;
    cursor: pointer;
  }
}
</style>