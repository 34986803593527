<template>
  <div>
    <BaseCard :card-name="tr('Patient Card')">
      <div class="patient-card">
        <h2 :style="h2Style">Patient Name</h2>
        <ul :style="ulStyle">
          <li>Age</li>
          <li>Disease</li>
          <li>Other</li>
        </ul>
      </div>
    </BaseCard>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { themeColors } from '@/common/shared.js'
import BaseCard from '../BaseCard/BaseCard.vue'
const tr = (x) => x // inject('tr')
const store = useStore()

const h2Style = computed(() => ({
  color: themeColors[store.getters.currentThemeName].toolHeaderTextColor,
}))
const ulStyle = computed(() => ({
  color: themeColors[store.getters.currentThemeName].toolHeaderTextColor,
}))

</script>

<style lang="scss" scoped>
.patient-card {
  padding: 5rem 1.75rem;
  text-align: left;
}

h2 {
  font-weight: normal;
}

ul {
  padding: 0;

  li {
    text-align: left;
    list-style: none;
  }
}
</style>