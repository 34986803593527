<template>
  <div>
    <BlackBoxToolCard :card-name="tr('Pathology')" :payload="payload"></BlackBoxToolCard>
  </div>
</template>

<script setup>
// import { inject } from 'vue'
import BlackBoxToolCard from '../BlackBoxToolCard/BlackBoxToolCard.vue'
import getEnv from '@/utils/env'
const tr = (x) => x // inject('tr')

const baseUrl = getEnv('VUE_APP_PATHOLOGY_VIEWER_ROUTE') || 'https://oda-aks-dev-ux2.westus2.cloudapp.azure.com/pathds/viewer/v1/mypatient'

const payload = {
  data: {},
  url: {
    baseUrl,
    method: 'GET',
    queryParams: {
      patientIds: { key: 'patient_identifier', required: true }
    }
  }
}

</script>

<style lang="scss" scoped></style>