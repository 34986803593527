<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :stroke="modalIconColor" fill="none">
    <title>{{ 'tbd' /* TODO */ }}</title>
    <g stroke-miterlimit="10">
      <path
        d="M428.556 461.039H83.442A64.743 64.743 0 0 1 18.705 396.3V50.961h172.557l64.286 86.392h237.747V396.3a64.746 64.746 0 0 1-39.965 59.81 64.746 64.746 0 0 1-24.774 4.929zM252.803 50.961H511.75"
        stroke-width="36.91062" />
    </g>
  </svg>
</template>

<script setup>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

const store = useStore()

const modalIconColor = computed(() => {
  return themeColors[store.getters.currentThemeName].toolHeaderTextColor
})

// const tr = inject('tr')
</script>