<template>
  <div>
    <BaseCard :card-name="tr('Filing Cabinet')"></BaseCard>
  </div>
</template>

<script setup>
// import { inject } from 'vue'
import BaseCard from '../BaseCard/BaseCard.vue'
const tr = (x) => x // inject('tr')

</script>

<style lang="scss" scoped></style>