<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :fill="modalIconColor">
    <title>{{ 'tbd' /* TODO */ }}</title>
    <g fill-rule="evenodd" clip-rule="evenodd">
      <path
        d="M183.162 329.543a18.225 18.225 0 0 1 0 25.796L31.692 506.66a18.287 18.287 0 0 1-25.848-.02 18.24 18.24 0 0 1 .018-25.814l151.47-151.284a18.273 18.273 0 0 1 12.915-5.342c4.844 0 9.49 1.92 12.915 5.342zM506.632 6.465a18.206 18.206 0 0 1 3.972 5.925 18.2 18.2 0 0 1 0 13.985 18.204 18.204 0 0 1-3.972 5.924L355.164 183.584a18.291 18.291 0 0 1-25.847.019 18.235 18.235 0 0 1-.02-25.817L480.806 6.465a18.278 18.278 0 0 1 25.827 0z" />
      <path
        d="M18.767 347.798c4.845 0 9.491 1.922 12.917 5.343a18.233 18.233 0 0 1 5.35 12.901v109.469h109.602c4.845 0 9.49 1.922 12.917 5.343a18.233 18.233 0 0 1 0 25.802A18.28 18.28 0 0 1 146.636 512H18.767a18.28 18.28 0 0 1-12.917-5.344 18.234 18.234 0 0 1-5.35-12.9V366.041c0-4.838 1.925-9.479 5.35-12.9a18.278 18.278 0 0 1 12.917-5.344zM164.903 19.368c0-4.839-1.925-9.48-5.35-12.901a18.279 18.279 0 0 0-12.917-5.344H18.767A18.278 18.278 0 0 0 5.85 6.467a18.234 18.234 0 0 0-5.35 12.9v127.714c0 4.838 1.925 9.48 5.35 12.9a18.278 18.278 0 0 0 12.917 5.344c4.845 0 9.491-1.922 12.917-5.343a18.234 18.234 0 0 0 5.35-12.901V37.613h109.602c4.844 0 9.49-1.923 12.917-5.344a18.234 18.234 0 0 0 5.35-12.901z" />
      <path
        d="M328.672 328.878a18.244 18.244 0 0 0-5.368 12.916c0 2.4.474 4.778 1.396 6.993a18.292 18.292 0 0 0 3.972 5.926L480.14 505.996a18.293 18.293 0 0 0 19.917 3.97 18.271 18.271 0 0 0 5.933-3.95 18.237 18.237 0 0 0 .016-25.818l-151.504-151.32a18.277 18.277 0 0 0-12.915-5.342c-4.845 0-9.49 1.92-12.915 5.342zM6.515 7.114A18.244 18.244 0 0 0 1.15 20.03a18.225 18.225 0 0 0 5.366 12.917l151.47 151.285a18.288 18.288 0 0 0 12.92 5.356 18.288 18.288 0 0 0 12.928-5.338 18.244 18.244 0 0 0 5.362-12.904 18.245 18.245 0 0 0-5.344-12.912L32.345 7.114A18.278 18.278 0 0 0 19.43 1.772c-4.844 0-9.49 1.921-12.915 5.342z" />
      <path
        d="M346.949 18.745c0-4.839 1.924-9.48 5.35-12.901A18.279 18.279 0 0 1 365.216.5h127.868c4.845 0 9.492 1.922 12.917 5.344a18.234 18.234 0 0 1 5.35 12.9v127.714c0 4.838-1.924 9.479-5.35 12.9a18.279 18.279 0 0 1-12.917 5.344 18.279 18.279 0 0 1-12.916-5.343 18.234 18.234 0 0 1-5.35-12.901V36.989H365.215a18.278 18.278 0 0 1-12.917-5.343 18.234 18.234 0 0 1-5.35-12.901zm146.135 328.404a18.279 18.279 0 0 0-12.916 5.345 18.224 18.224 0 0 0-5.35 12.9v109.468H365.215a18.279 18.279 0 0 0-12.917 5.345 18.224 18.224 0 0 0 0 25.799 18.279 18.279 0 0 0 12.917 5.345h127.868c4.845 0 9.492-1.923 12.917-5.345a18.224 18.224 0 0 0 5.35-12.9V365.395c0-4.84-1.924-9.481-5.35-12.9a18.279 18.279 0 0 0-12.917-5.345z" />
    </g>
  </svg>
</template>

<script setup>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

const store = useStore()

const modalIconColor = computed(() => {
  return themeColors[store.getters.currentThemeName].toolHeaderTextColor
})

// const tr = inject('tr')
</script>