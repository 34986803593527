<template>
  <div>
    <BlackBoxToolCard :card-name="tr('Genomics')" :payload="payload"></BlackBoxToolCard>
  </div>
</template>

<script setup>
// import { inject } from 'vue'
import BlackBoxToolCard from '../BlackBoxToolCard/BlackBoxToolCard.vue'
import getEnv from '@/utils/env'
const tr = (x) => x // inject('tr')

const baseUrl = getEnv('VUE_APP_GENOMIC_ANNOTATION_BASE_URL') || 'https://oda-aks-dev-ux2.westus2.cloudapp.azure.com/GenericAnnotation'

const payload = {
  data: {
    startDate: '2015-01-01',
    endDate: '2021-12-31',
    nomenu: true
  },
  url: {
    baseUrl,
    method: 'GET',
    queryParams: {
      endDate: { key: 'date2', required: true },
      language: { key: 'language', required: true },
      location: { key: 'location', required: true },
      startDate: { key: 'date1', required: true },
      nomenu: { key: 'nomenu', required: false },
      patientId: { key: 'query', required: true }
    }
  }
}

</script>

<style lang="scss" scoped></style>