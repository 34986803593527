<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :fill="modalIconColor">
    <title>{{ 'tbd' /* TODO */ }}</title>
    <path
      d="M511.75 98.615c0-39.87-32.264-72.134-72.135-72.134H72.385C32.514 26.48.25 58.745.25 98.615c0 32.789 22.296 60.331 52.462 68.987v193.321c0 68.725 55.871 124.596 124.596 124.596h157.384c68.725 0 124.596-55.871 124.596-124.596v-193.32c30.166-8.657 52.462-36.2 52.462-68.988ZM334.692 446.173H177.308c-46.953 0-85.25-38.297-85.25-85.25V170.75h327.884v190.173c0 46.953-38.297 85.25-85.25 85.25zm104.923-314.77H72.385c-18.1 0-32.789-14.688-32.789-32.788 0-18.099 14.69-32.788 32.789-32.788h367.23c18.1 0 32.789 14.69 32.789 32.788 0 18.1-14.69 32.789-32.789 32.789z"
      style="stroke-width:26.22992126;stroke-dasharray:none" />
    <path d="M7 .25H1C.59.25.25.59.25 1s.34.75.75.75h6c.41 0 .75-.34.75-.75S7.41.25 7 .25Z"
      style="stroke-width:.99998677;stroke-dasharray:none" transform="matrix(23.8 0 0 23.8 160.8 232.2)" />
  </svg>
</template>

<script setup>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

const store = useStore()

const modalIconColor = computed(() => {
  return themeColors[store.getters.currentThemeName].toolHeaderTextColor
})

// const tr = inject('tr')
</script>